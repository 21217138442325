.c-dots-loader {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: color(teal);
  color: color(teal);
  box-shadow: 9999px 0 0 -5px color(teal);
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__bg {
    height: 100%;
    width: 100%;
    background: white;
  }
}

.c-dots-loader::before, .c-dots-loader::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: color(teal);
  color: color(teal);
}

.c-dots-loader::before {
  box-shadow: 9984px 0 0 -5px color(teal);
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.c-dots-loader::after {
  box-shadow: 10014px 0 0 -5px color(teal);
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px color(teal);
  }
  30% {
    box-shadow: 9984px 0 0 2px color(teal);
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px color(teal);
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px color(teal);
  }
  30% {
    box-shadow: 9999px 0 0 2px color(teal);
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px color(teal);
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px color(teal);
  }
  30% {
    box-shadow: 10014px 0 0 2px color(teal);
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px color(teal);
  }
}
