/*
* Colors
* Define colors in the $color map, and grab them using the function
* i.e. background-color: color(yellow);
*/

$colors: (
  white: #fff,
  grey-100: #ebebeb, // consolidate w/ #e6e7e8
  grey-200: #ccc, // consolidate w/ #d3d6d7 & #bbb
  grey-300: #9ea9b0,
  grey-400: #555,
  teal: #416679,
  navy: #11212c,
  navy-100: #101e26,
  navy-200: #041218,
  black: #000,
  orange: #f47e23,
  red: #c53e3e,
  green: #4ea740,
  loading: #0f0b523d
);

@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn 'That color is not defined: \'#{$key}\'';
}
