.c-collection-detail {
  &__sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__sidebar-img {
    width: 100%;
  }

  &__sidebar-img-container {
    display: flex;
    overflow: hidden;
    max-height: 30rem;
    background: url('../../shared/assets/images/jda_default-large-logo.jpg');
    background-color: color(navy);
    background-size: cover;
    align-items: center;
    justify-content: center;
    
    &--loading {
      height: 30rem;
    }
  }

  &__description {
    ul, ol {
      padding-left: 3rem;
    }
  }

  &__content {
    flex: 1;

    &_addslide {
      button {
        margin-left: 2rem;
      }

      .material-icons {
        margin-right: 0.5rem;
      }
    }
  }

  &__photo {
    width: 100%;
  }
}
