.c-user-profile {
  &__content {
    overflow: auto;
    flex: 1;
  }

  &__avatar {
    height: 30rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__s-title {
    font-size: 2rem;
    font-weight: 700;
    padding: 1.6rem;
    background: color(grey-300);
  }

  &__s-heading {
    font-size: 1.28rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__list {
    list-style: none;
  }

  &__contributions {
    padding: 0 4.8rem 4.8rem;
  }

  &__contribution {
    border: 0.1rem solid color(grey-100);
  }
}
blockquote {
  background: #f9f9f9;
  border-left: 2px solid #ccc;
  padding: 10px 2em;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  vertical-align: -0.4em;
  margin-left: -0.5em;
}
blockquote:after {
  color: #ccc;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  vertical-align: -0.6em;
  margin-left: -0.5em;
}
blockquote p {
  display: block;
}
