.c-alert-banner {
  background-color: color(teal);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: color(white);

  @include iota-breakpoint(md) {
    padding: 16px 36px;
  }

  > svg {
    cursor: pointer;
  }

  &__left {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    svg {
      margin-right: 10px;
    }
    a {
      margin-left: 4px;
      text-decoration: underline;
      color: color(white);
      &:hover {
        color: color(navy);
      }
    }
  }
}
