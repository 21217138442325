.c-progress {
  background-color: color(orange);
  height: 0.5rem;
  width: 0;
  border-radius: 1rem;
  transition: 0.5s ease;
  transition-delay: 0.5s;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  &__bar {
    background-color: color(orange);
    height: 0.5rem;
  }
}

