.drag-and-drop{
    height: 200px;
    width: 100%;
    background-color: rgba(0,0,0,0.02);
    display: flex;
    border: 2px dashed rgba(0, 0, 0, 0.02);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drag-text{
    font-size: 16px;
    color: #000000;
}

.drag-accepted-text{
    font-size: 14px;
    color: #BBBBBB;
}

.text-center{
    text-align: center;
}

.drag-add-items {
    color: #000000;
    font-weight: bold;
}

.drag-max-items {
    color: #666666;
    font-size: 14px;
}

.drag-head, .websites-head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.websites-input-wrapper {
    .c-input-wrapper {
        box-sizing: border-box;
        background-color: #FFFFFF;
        margin-bottom: 16px;
        .c-text-input {
            border: 1px solid #656565;
            border-radius: 3px;
        }
    }
}

.add-more-button {
    display: inline-flex;
    margin-top: 10px;
    align-items: center;
    cursor: pointer;
    .add-more {
        color: #11212C;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.1px;
        line-height: 16px;
        margin-left: 7px;
      }
}

.file-list-text{
    color: #000000;
    font-size: 14px;
}

.file-list-wrapper{
    margin-top: 10px;
}

.file-list{
    
    margin-bottom: 10px;
}

.file-list-error{
    border-bottom: 2px solid #E50000;
    color: #BBBBBB;
}

.file-list-container{
    display: flex;
    align-items: center;
}

.list-item-status {
    width: 100%;
    height: 2px;
    &.error{
        background-color: #E50000;
    }
    &.loading {
        background-color: rgba(0,0,0,0.2);
        .bg {
            width: 50%;
            height: 2px;
            background-color: #416679;
        }
    }
    &.success {
        background-color: #416679;
    }
}

.file-container {
    width: 70%;
}

.file-name-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

.file-name{
    // margin-left: 10px;
}

.file-attachment{
    margin-right: 10px;
}

.file-list-actions{
    display: flex;
    align-items: center;
    & i {
        cursor: pointer;
    }
}

.file-list-actions div{
    padding: 0 5px;
}

.close-icon{
    cursor: pointer;
}
