.c-link {
  color: inherit;
  font-size: inherit;
  border: none;
  background: transparent;
  padding: 0;
  transition: 0.3s ease all;

  &:visited {
    color: inherit;
  }

  &--default {
    text-decoration: underline;
  }

  &--bold {
    font-weight: $bold;
  }

  &--icon {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
  }

  &--footer {
    color: color(black);
    font-size: 1.36rem;
    padding: 1.3rem;
    text-decoration: none;
  }

  &__icon {
    display: flex;
    align-items: center;

    &--left {
      margin-right: 1rem;
    }

    &--right {
      margin-left: 1rem;
    }

    &--lg {
      .material-icons {
        font-size: 3.2rem;
      }
    }
  }
}
