//Typography
$lato:"Lato","Helvetica Neue","Helvetica",Arial,sans-serif;

//Color
$orange:	#f47e23;
$dark:		#11212c;
$light:		#416679;
$grey--1:	#e6e7e8;
$grey--2:	#d3d6d7;
$grey--3:	#9ea9b0;
$red:		#c53e3e;

//Z-index
$z1:100;
$z2:200;
$z3:300;
$z4:400;
$z5:500;
$z6:600;
$z7:700;
$z8:800;
$z9:900;

//Breakpoints
$desktop-s:		max-width 1366px;
$tablet-l:		max-width 1024px;
$tablet-p:		max-width 768px;
$mobile:		max-width 767px;
$height-front: 	max-height 950px;
$height-m:		max-height 768px;
$height-s:		max-height 600px;
$height-xs:		max-height 480px;

//Transitions
$eo100:	100ms ease-out all;
$eo250:	250ms ease-out all;
$eo500:	500ms ease-out all;
$l100:	100ms linear all;
$l250:	250ms linear all;
$l500:	500ms linear all;

//Settings
$setting--sidebar:			400px;
$setting--sidebar--m:		225px;
$setting--sidebar--s:		175px;
$setting--border-radius:	0.4em;
$setting--overlay-opacity:	rgba(0,0,0,0.5);
$setting--box-shadow:		0 0em 3em rgba(0,0,0,0.25);

$mediatypes:website document audio image testimonial tweet video;