
.c-contribute-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__tabber {
    overflow: hidden;
    flex: 1;
  }

  &__header {
    border-bottom: 1px solid color(grey-300);
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
  }

  &__content-wrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__content {
    flex: 1;
    height: 100%;
    width: 200%;
    transition: all ease-out 250ms;

    &.js-step-1 {
      transform: translateX(0);
    }
    &.js-step-2 {
      transform: translateX(-50%);
    }

    &--success {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__btn {
    padding: 1.6rem;
    text-transform: uppercase;
  }

  &__step {
    display: inline-block;
    padding: 3.2rem 3.2rem 0 3.2rem;
    vertical-align: top;
    width: 50%;

    &--scroll {
      height: 100%;
      overflow: auto;
    }
  }

  &__col-list {
    flex: 1;
    overflow: auto;
  }
}

.upload-type{
  display: flex;
  // margin-top: 5px;
}

.upload-type-items{
  margin-right: 10px;
}

.radio-btn{
  accent-color: #416579;
}

.contributes-text{
  color: #000000;
  font-weight: bold;
}
