.c-featured-card {
  border: 0.1rem solid #CCCCCC;
  box-sizing: border-box;
  list-style: none;
  margin-bottom: 3.2rem;
  min-height: 30rem;

  &__title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  &__description {
    margin-bottom: 1rem;
  }

  &__info-block {
    padding: 1.8rem 2rem;
    font-size: 1.5rem;
    letter-spacing: -.02rem;
    line-height: 2.1rem;
  }

  &__link {
    color: color(teal);
    font-size: 1.5rem;
  }

  &__img {
    background-image: url('../../../../../shared/assets/images/jda_default-large-logo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20.6rem;
  }
}
