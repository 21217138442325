$header-height-xs: 11.511rem;
$header-height-md: 8.711rem;
$footer-height: 4.8rem;

.c-layout {
  @include iota-breakpoint(md) {
    display: flex;
    flex-flow: column nowrap;
  }

  &--no-scroll {
    overflow: scroll;
    height: 100%;
  }

  &__main {
    background: color(grey-100);

    @include iota-breakpoint(md) {
      display: flex;
      width: 100%;
      height: calc(100% - 14rem);
      flex: 1;
    }
  }

  &__centered {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__content {
    @include iota-breakpoint(md) {
      flex: 1;
    }
  }

  &__narrow {
    // vh - avg header height (hides footer on xs to s screens)
    height: calc(100vh - #{$header-height-xs});
    overflow: hidden;
    
    @include iota-breakpoint(md) {
      // vh - avg header height - avg footer height on desktop
      height: calc(100vh - #{$header-height-md} - #{$footer-height});
    }
  }

  &__fill {
    // vh - avg header height (hides footer on xs to s screens)
    min-height: calc(100vh - #{$header-height-xs});
    
    @include iota-breakpoint(md) {
      // vh - avg header height - avg footer height on desktop
      min-height: calc(100vh - #{$header-height-md} - #{$footer-height});
    }
  }
}
