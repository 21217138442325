.c-basic-page {
  background: color(white);
  display: flex;
  flex-direction: column;

  @include iota-breakpoint (md) {
      flex-direction: row;
  }

  &__sidebar {
    background: color(grey-200);
  
    @include iota-breakpoint (md) {
      width: 40rem;
    }

    &--user-profile {
      background: color(grey-100);
    }
    .c-link {
      position: unset !important;
    }
  }

  &__content {
    padding: 0.8rem;
    width: 100%;
    
    @include iota-breakpoint (md) {
      padding: 4.8rem;
      max-width: 80rem;
    }

    &--user-profile {
      overflow: auto;
    }

    &--center {
      margin: 0 auto;
    }
  }
}