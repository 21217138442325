.c-wysiwyg {
  ul, ol {
    padding-left: 3rem;
  }
  h1 {
    @extend .c-heading;
    @extend .c-heading--l1;
  }

  h2 {
    @extend .c-heading;
    @extend .c-heading--l2;
  }

  h3 {
    @extend .c-heading;
    @extend .c-heading--l3;
  }

  h4 { // NOTE: h4 has been subbed for the uppercase h3
    @extend .c-heading;
    @extend .c-heading--l3;
    @extend .c-heading--uppercase;
  }

  h5 {
    @extend .c-heading;
    @extend .c-heading--l5;
  }

  h6 {
    @extend .c-heading;
    @extend .c-heading--l6;
  }

  p {
    margin-bottom: 10px;

    img {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  // a {
  //   color: color(black);
  //   text-decoration: underline;
  //   transition-duration: 0.2s;
  // }

  &--split-content {
    p:first-child {
      margin-top: 0;
    }
  }
}
