.c-bookmarklet {
  border-radius: 0.64rem;
  border: 0.4rem dashed color(grey-300);
  padding: 3.2rem;
  width: 52%;

  &__btn {
    text-transform: uppercase;
  }
}
