.c-date-input {
  &__popper {
    transform: none !important;
  }

  .react-datepicker__tab-loop {
    position: relative;
  }

  .react-datepicker__input-container {
    input {
      border: 0.1rem solid color(grey-300);
    }
  }
}
