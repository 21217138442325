.c-tag-manager {
  display: flex;

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
  }

  &__tag {
    margin: 0 0.4rem 0.4rem 0;
    position: relative;

    & input {
      display: inline-block;
      // padding: 0.4rem 0.8rem;
      color: color(teal);
      border: 1px solid color(teal);
      // text-transform: uppercase;
      border-radius: 0.64rem;
    }
  }

  &__field-size-calculator {
    font-size: 14px;
    font: lato;
    position: absolute;
    visibility: hidden;
    text-transform: uppercase;
  }

  &__tag-remove-btn {
    font-size: 1rem;
    color: color(white);
    background: color(teal);
    position: absolute;
    z-index: 10;
    top: -1rem;
    right: -1rem;
    display: flex;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    align-items: center;
    justify-content: center;

    &:hover {
      background: color(red);
      & + .c-tag {
        opacity: 0.75;
      }
    }
  }
}
