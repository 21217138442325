
  .c-toolbar {
    background: color(grey-200);
    display: flex;
    height: 6rem;
    padding: 0 1.2rem;
    align-items: center;
    justify-content: space-between;

    &--my-collections {
      justify-content: space-between;

      .c-heading--l1 {
        font-size: 1.6rem;
        @include iota-breakpoint(sm) {
          font-size: 3.2rem;
        }
      }
    }

    &__header-col {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &__heading {
      font-weight: 700;
      padding: 0.7rem 2rem 0.7rem 0;
      border-right: none;
      margin-right: 0;

      @include iota-breakpoint(md) {
        border-right: 0.1rem solid color(grey-300);
        margin-right: 2rem;
      }
    }

    &__views {
      border-right: none;
      margin-right: 0;

      @include iota-breakpoint(md) {
        border-right: 0.1rem solid color(grey-300);
        margin-right: 2rem;
      }

      &--no-border {
        @include iota-breakpoint(md) {
          border-right: none;
        }
      }
    }

    &__count {
      margin-left: auto;
      display: none;

      @include iota-breakpoint(xs) {
        display: block;
      }
    }

    

    &__settings-btn {
      transition: 100ms linear all;
      color: color(grey-300);

      &:hover {
        color: #829099;
      }
    }

    &__create-new-btn {
      border-radius: 50%;
      padding: 0.8rem;

      @include iota-breakpoint(sm) {
        border-radius: 0.64rem;
        padding: 1.3rem;
      }
    }

    .c-dropdown__label {
      display: none;

      @include iota-breakpoint(md) {
        display: inline;
      }
    }

    .c-dropdown__input {
      font-size: 1.2rem;

      @include iota-breakpoint(lg) {
        font-size: 1.4rem;
      }
    }

    .c-checkbox {
      .c-btn {
        padding: 0.8rem 0.5rem;

        @include iota-breakpoint(lg) {
          padding: 0.8rem 1.6rem;
        }
      }
    }

    .c-checkbox__text {
      display: none;

      @include iota-breakpoint(lg) {
        display: inline;
      }
    }

    .c-btn__icon {
      margin-right: 0;

      @include iota-breakpoint(lg) {
        margin-right: 1rem;
      }
    }
  }