.c-account-form {
  background: color(white);
  border-radius: 0.64rem;

  &__header {
    border-top-left-radius: 0.64rem;
    border-top-right-radius: 0.64rem;
    background: color(navy);
    padding: 1.2rem 1.6rem;

    &--empty {
      min-height: 4.8rem;
    }
  }

  &__content {
    padding: 1.6rem;

    &--confirm {
      padding: 0.8rem;
    }
  }

  &__registration-links {
    display: flex;
    font-size: 1.36rem;
    justify-content: flex-end;
    list-style: none;
  }

  &__reg-link {
    margin-left: 1.2rem;
  }

  &__input {
    border: 0.1rem solid color(grey-100);
  }
}