.c-nav {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 250ms ease-out all;
  background-color: color(navy-200);
  top: 4.6rem;
  text-align: center;

  @include iota-breakpoint(md) {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    overflow: visible;
    top: 0;
  }

  &.is-open {
    height: 100%;
    padding: 2.4rem;
    overflow: scroll;

    @include iota-breakpoint(md) {
      padding: 0;
      height: inherit;
      overflow: inherit;
    }
  }
}
