.c-btn {
  color: inherit;
  border: none;
  transition: 0.3s ease all;
  text-decoration: none;
  font-size: 1.6rem; // remove and set to inherit if not needed
  font-weight: $bold; // consolidated button font weights
  border-radius: 0.64rem;
  display: inline-flex;
  align-items: center;
  line-height: 1.58;
  margin: 0; // removes default margin from safari
  cursor: pointer;

  &.is-disabled,
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  // size
  &--sm {
    padding: 1.3rem;
    font-size: 1.3rem; // one instance of override
    line-height: 1;
  }

  &--sm-p {
    padding: 1.2rem;
  }

  &--md {
    padding: 0.5rem 1.6rem;
  }

  &--lg {
    padding: 0.8rem 1.6rem;
  }

  // color
  &--transparent {
    background: none;
    color: color(navy);

    &:hover,
    &:active,
    &.is-active {
      color: color(white);
    }

    &:hover {
      background: color(teal);
    }

    &:active,
    &.is-active {
      background: color(navy);
    }
  }

  // width
  &--full {
    width: 100%;
    justify-content: center;
  }

  // unique variations
  &__icon {
    display: flex;
    align-items: center;

    &--sm {
      .material-icons {
        font-size: 1.6rem;
      }
    }

    &--lg {
      .material-icons {
        font-size: 3.2rem;
      }
    }

    &--left {
      margin-right: 1rem;
    }

    &--right {
      margin-left: 1rem;
    }
  }

  &--bare {
    background: none;
    padding: 0;
  }

   &--sharp {
    border-radius: 0;
  }

  &--hover-fx {
    &:hover {
      opacity: 0.8;
    }
  }
}
