.c-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -3rem; /* gutter size offset */
  width: auto;
}
.c-masonry-grid_column {
  padding-left: 3rem; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.c-masonry-grid_column > .c-masonry-grid__item { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 3rem;
}

// Additional BEM
.c-masonry-grid {

  &__item {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0); // fixes react dnd preview on chrome
  }

  &__btn {
    display: block;
    border: none;
    padding: 0;
    background-color: #416679;
    width: 100%;
    color: #fff;
    min-height: 5rem;
  }

  &__img {
    width: 100%;
  }

  &__plus-btn {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    border-radius: 100% !important;
    box-shadow: 0 0 1rem 0 rgba(0,0,0,.5);
  }
}