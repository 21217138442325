.c-input-wrapper {
  &__required {
    color: red;
    font-size: 1.6rem;
  }

  &__error {
    color: #8c2e0b;
  }

  &__label {
    font-size: 1.36rem;
    font-weight: bold;
    text-transform: uppercase;

    &--create {
      font-size: 1.6rem;
    }

    &--user-profile {
      font-size: 1.6rem;
      text-transform: initial;
    }
  }

  &__label-ex {
    font-weight: 400;
    text-transform: initial;
  }

  &_label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .ck-editor__editable {
    padding-left: 30px !important;
    height: 250px !important;
    p {
      margin-bottom: 10px;
    }
  }

  .file__upload_label {
    background-color: transparent;
    color: white;
    font-family: sans-serif;
    cursor: pointer;
    .title {
      border: 1px solid black;
      display: inline-block;
      border-radius: 3px;
      background-color: rgb(236, 233, 233);
      padding: 0.2rem 0.7rem;
      color: black;
    }
    .detail {
      color: black;
      margin-left: 1rem;
    }
  }

  .file__upload_label {
    background-color: transparent;
    color: white;
    font-family: sans-serif;
    cursor: pointer;
    .title {
      border: 1px solid black;
      display: inline-block;
      border-radius: 3px;
      background-color: rgb(236, 233, 233);
      padding: 0.2rem 0.7rem;
      color: black;
    }
    .detail {
      color: black;
      margin-left: 1rem;
    }
  }
}
