.head-text {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}

.multiform-container{
    margin-top: 20px;

    .an-form-container{
        border: 1px solid #91989C;
        .multiform-inputs{
            background-color: #F9F9F9;
            padding: 20px;
            .c-text-input   {
                background-color: white;
                border: 1px solid #656565;
                border-radius: 3px;
            }
        }
    }
}

.multiform-head{
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 5px;
}

.multiform-fileinfo-container{
    display: flex;
    border-bottom: 1px solid #91989C;

}

.multiform-fileinfo-right{
    width: 125px;
    height: 84px;
    position: relative;
    background-color: #11212c;
    margin-right: 10px;
}

.multiform-fileinfo-right i{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    // width: 100%;
}

.multiform-fileinfo-left{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.multiform-fileinfo {
    .c-collection-item__subitem {
        margin: 0;
        margin-right: 1em;
    }
}

.file-type{
    display: flex;  
    align-items: center;
}

.file-remove{
    display: flex;  
    align-items: center;
    padding-right: 16px;
    cursor: pointer;
}

.file-name{
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.file-type{
    font-size: 13px;
    color: #555555;
    font-style: italic;
}

.file-icon{
    margin-right: 2px;
}

.remove-text{
    color: #416579;
    font-size: 15px;
    font-weight: 600;
    margin-left: 5px;
}

.close-icon{
    width: 12px;
    height: 16px;
}

