.c-collection-topic {
  margin-bottom: 1rem;

  &__blurb {
    margin-bottom: 1.2rem;
  }

  &__empty {
    height: 8.5rem;
    border: Dashed 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__add {
    height: 2.5rem;
    padding-top: 1rem;

    &:hover {
      .c-collection-topic__add_wrapper {
        display: block;
      }
    }

    &_wrapper {
      border-top: color(grey-300) dashed 0.1rem;
      position: relative;
      display: block;

      @include iota-breakpoint(sm){
        display: none;
      }
    }

    .c-btn {
      color: color(teal);
      font-size: 1.5rem;
      letter-spacing: -0.01rem;
      background-color: color(grey-100);
      padding: 0 1rem;
      position: absolute;
      top: -1.5rem;
      left: calc(50% - 71px);

      .material-icons {
        margin-right: 0.3rem;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__actions {
    display: flex;
    margin-bottom: 1.2rem;

    .c-btn {
      margin-left: 0.5rem;
      color: color(teal)
    }
  }

  &__items {
    height: 0;
    overflow: hidden;
    transition: all 2s;

    &.is-open {
      height: auto;
    }
  }

  .c-heading {
    display: flex;

    .c-btn {
      display: none;
      margin: 0 0 0 0.5rem;
    }

    &:hover {
      .c-btn {
        display: block;
      }
    }
  }
}