.c-partner {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.2rem;
  padding: 1.6rem;
  border-radius: 0.64rem;
  background: color(white);
  align-items: center;

  &__close {
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.8rem;
    cursor: pointer;

    i {
      font-size: 2.4rem;
      overflow: hidden;
      padding: .36rem;
      color: #fff;
      border-radius: 100%;
      background: color(orange);
    }
  }

  img {
    width: 13.5rem;
    margin-right: 1.6rem;
    border-radius: .64rem;
  }

  &__text_name {
    font-size: 3.2rem;
    font-weight: 700;
  }

  &__text_date {
    font-size: 1.36rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}