.c-tophat {
  background-color: color(navy-100);
  display: none;
  justify-content: flex-end;
  align-items: center;
  color: color(white);

  @include iota-breakpoint(md) {
    display: flex;
  }

  &__items {
    list-style: none;
    margin-right: 2rem;
  }

  &__item {
    font-size: 1.3rem;

    &-link {
      color: color(white);
    }
  }

  &__switch {
    font-weight: 700;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    background-color: color(teal);
    padding: 1rem 1.5rem;

    &--mobile {
      background-color: transparent;

      .c-tophat__lang,
      .c-tophat__toggle {
        font-size: 2rem;
      }

      .c-tophat__toggle {
        background-color: color(grey-100);
        height: 2.5rem;
        width: 5rem;

        &-inner {
          background-color: color(teal);
          top: 4%;
          width: 46%;
          height: 90%;
        }
      }
    }
  }

  &__lang {
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 1.1rem;
    padding: 0;
    color: color(white);
  }

  &__toggle {
    position: relative;
    border: none;
    display: block;
    height: 2.1rem;
    padding: 0;
    width: 4rem;
    margin: 0 1.5rem;
    border-radius: 1.6rem;
    background: color(navy);

    &-inner {
      position: absolute;
      top: 8%;
      width: 42%;
      height: 84%;
      border-radius: 100%;
      background: color(grey-100);
    }

    &--ja {
      .c-tophat__toggle-inner {
        right: 0;
      }
    }
  }
}