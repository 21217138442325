.c-dropdown {
  display: flex;
  align-items: center;

  &__input {
    font-size: 1.4rem;
    font-weight: 700;
    position: relative;
    margin-left: 1.3rem;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    border-radius: 0.6rem;
    background: color(grey-100);
    cursor: pointer;
  }

  &__current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12rem;
  
    @include iota-breakpoint(sm) {
      width: 16.7rem;
  
      &--ja {
        width: 18.3rem;
      }
    }

    i {
      margin-left: 0.5rem;
      font-size: 1.4rem;
      padding-left: 0.5rem;
      border-left: 1px solid color(grey-300);
    }

    span {
      overflow: hidden;
    }
  }

  &__options {
    list-style: none;
    position: absolute;
    z-index: 95;
    top: 100%;
    top: calc(100% + 0.7rem);
    left: 0;
    display: none;
    overflow: hidden;
    min-width: 100%;
    white-space: nowrap;
    border-radius: .4rem;
    box-shadow: 0 1.3rem 4rem 0 rgba(0,0,0,.25);

    &.is-open {
      display: block;
    }
  }

  &__option {
    padding: 1rem;
    transition: 100ms linear all;
    background: color(grey-100);

    &:hover {
      background-color: color(white);
    }
  }
}