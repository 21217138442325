.c-collection-preview {
  height: 100%;
  
  &__img {
    max-height: 25rem;
  }

  &__info {
    display: flex;
    flex: 0.3;
    padding: 2rem;
  }

  &__info-col {
    flex: 0.5;
  }

  &__items {
    background: color(grey-100);
    flex: 0.7;
    overflow: auto;
    padding: 0.8rem;
  }

  &__tabber {
    height: 100%;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__description {
    ul, ol {
      padding-left: 3rem;
    }
  }
}
