.c-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.36rem;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s ease all;
  color: color(white);
  border-radius: 0.5rem;

  &:hover {
    opacity: 0.75;
  }

  &--default {
    padding: 0.95rem 1.36rem;
    border: 0 solid transparent;
  }

  &--add {
    padding: 0.7rem 1.36rem;
    border: 0.1rem dashed color(white);
    background: #7fa6ba;

    &:hover {
      background: color(grey-300);
    }
  }

  &--lang {
    font-weight: 700;
    padding: 1.2rem;
    text-transform: capitalize;
    text-align: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &--add {
      margin-right: 0.5rem;

      .material-icons {
        font-size: 1.6rem;
      }
    }
  }
}
