.c-header {
  background: color(navy-200);
  // position: fixed;
  // left: 0;
  // top: 0;
  width: 100%;
  position: relative;
  
  @include iota-breakpoint(md) {
    // position: relative;
    left: unset;
    top: unset;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    max-width: 25rem;
  }

  &__signup {
    // max-width: 22.5rem;
    margin: 0.5rem;
  }

  &__toggle {
    color: color(white);
    display: block;
    z-index: 999;
    position: absolute;
    top: 0.4rem;
    right: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    cursor: pointer;

    @include iota-breakpoint(md) {
      display: none;
    }

    .material-icons {
      font-size: 4rem;
    }
  }
}
