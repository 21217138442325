.c-filters {
  margin-top: 2rem;

  &__toggle {
    margin-bottom: 2rem;

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-tooltip {
      width: 330px;
      background-color: white;
      padding: 8px 6px;
      border-radius: 10px;
      display: none;
      &--visible {
        display: block;
        border: 1px solid color(grey-200)
      }
    }

    &-info {
      display: block;
      @include iota-breakpoint(xs) {
        display: none;
      }
    }

    .c-link {
      text-transform: uppercase;
      color: color(navy);

      .material-icons {
        margin-right: 0.7rem;
      }
    }
  }

  &__container {
    display: none;

    &.is-open {
      display: block;
    }
  }

  &__heading {
    color: color(teal);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;

    @include iota-breakpoint(sm) {
      margin-bottom: 2rem;
    }
  }

  &__right {
    flex: 20%;
    margin-bottom: 12rem;

    @include iota-breakpoint(sm) {
      margin-bottom: 0;
    }

    .c-checkbox {
      width: calc(50% - 10px);
      margin-bottom: 0.75rem;

      @include iota-breakpoint(md) {
        width: 100%;
      }
    }
  }

  &__bottom {
    margin-top: 2rem;
    display: block;
    justify-content: space-between;

    @include iota-breakpoint(sm) {
      display: flex;
    }
  }

  &__typeall {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;

    .c-filters__heading {
      margin-right: 1rem;
      margin-bottom: 0;
    }

    .c-checkbox {
      margin-bottom: 0;
    }

    .c-btn {
      width: 12.5rem;
      font-weight: 300;
    }
  }

  &__typerest {
    .c-checkbox {
      width: calc(50% - 10px);
      margin-right: 1rem;
      margin-bottom: 0.75rem;

      &:nth-child(2n) {
        margin-right: 0;
        width: 50%;
      }

      @include iota-breakpoint(xs) {
        width: calc(33.3% - 10px);
        margin-right: 1rem;

        &:nth-child(2n) {
          margin-right: 1rem;
          width: calc(33.3% - 10px);
        }

        &:nth-child(3n) {
          margin-right: 0;
          width: 33.3%;
        }
      }

      @include iota-breakpoint(sm) {
        width: calc(20% - 10px);

        &:nth-child(2n),
        &:nth-child(3n) {
          margin-right: 1rem;
          width: calc(20% - 10px);
        }
      }

      .c-btn {
        font-size: 1.4rem;
        text-align: center;
      }

      .c-btn--lg {
        padding: 0.8rem 1rem;

        @include iota-breakpoint(xs) {
          padding: 0.8rem 1.6rem;
        }
      }

      .c-checkbox__btn--media {
        font-size: 1.4rem;
        text-align: center;
      }
    }
  }

  &__partners {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;

    @include iota-breakpoint(sm) {
      width: calc(20% - 10px);
      margin-right: 1rem;
    }
  }

  &__dates {
    width: 100%;
    margin-bottom: 2rem;

    @include iota-breakpoint(sm) {
      width: 40%;
    }

    &-inputs {
      display: inline-flex;
      margin-right: 1rem;
      width: calc(50% - 10px);

      input[type=text] {
        font-size: 1.6rem;
        padding: 0.7rem 1.1rem;
        border-radius: 0.64rem;
        border: 1px solid color(grey-200);
        width: 100%;
      }
    }
  }

  &__actions {
    margin-top: 4rem;

    @include iota-breakpoint(sm) {
      margin-top: 6rem;
    }

    .c-btn {
      width: 50%;
      justify-content: center;
    }
  }

  &__select {
    .react-select-box-options {
      max-height: 40vh;
      overflow: auto;
    }
  }
}
