.c-searchformwrapper {
  position: absolute;
  width: 100%;
  top: 0;
}

.c-searchform-jpwrapper {
  &--withnotice {
    padding-bottom: 1rem;
    background-color: color(grey-100);
    
    .c-searchform__notice {
      margin: .5rem 1.4rem 0 1.4rem;
      @include iota-breakpoint(md) {
        margin-left: 2.4rem;
      }
    }
  }

  +.c-filters {
    margin: 0;
    position: fixed;
    height: 100%;
    z-index: 99;
    background-color: color(grey-100);
    overflow: auto;

    @include iota-breakpoint(sm) {
      position: relative;
      overflow: inherit;
    }

    .c-filters__toggle {
      display: none;
    }
    .c-filters__container {
      padding: 1rem;

      @include iota-breakpoint(xs) {
        padding: 3rem;
      }
    }
  }  
}

.c-searchform {
  display: block;

  @include iota-breakpoint(xs) {
    display: flex;
  }

  &--full {
    background-color: color(white);
    padding: 0.5rem 1rem;
    display: flex;
    
    @include iota-breakpoint(xs) {
      padding: 1rem;
    }

    .c-searchform__bar {
      align-items: center;
      display: flex;
      width: 100%;

      .material-icon {
        padding: 1rem;
        font-size: 3.2rem;
      }
    }

    .c-searchform__bar-search {
      border-radius: 0;
      height: 4rem;
      padding: 0.5rem 1rem;

      @include iota-breakpoint(xs) {
        height: 5rem;
        padding: 1rem;
      }
    }

    .c-checkbox {
      margin: 0.25rem 1.5rem 0.25rem 0;
      display: none;

      @include iota-breakpoint(sm) {
        display: inline-flex;
      }
    }

    .c-searchform__btn {
      margin-right: 0.75rem;
    }

    .c-btn {
      font-size: 1.2rem;
      padding: 0.8rem 1rem;

      @include iota-breakpoint(md) {
        font-size: 1.6rem;
        padding: 0.8rem 1.6rem;
      }
    }

    .c-searchform__submit {
      margin-top: 0;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__checkbox {
    display: inline-flex;
    margin-right: 1rem;

    .c-btn {
      font-size: 1.4rem;
      white-space: nowrap;
    }

    input:checked {
      +.c-btn {
        background-color: color(teal);
        color: color(white);
      }
    }

    input:focus {
      +.c-btn {
        border-color: color(teal);
        outline: auto 0.5rem color(teal);
        outline-offset: -0.2rem;
      }
    }
  }

  &__bar {
    width: 75%;
    display: inline-flex;
    
    @include iota-breakpoint(xs) {
      width: 100%;
    }

    &-search {
      border-radius: 0.64rem 0 0 0.64rem;
      padding: 1rem;
      width: 100%;
      border: none;
      font-size: 2rem;
      line-height: 1.5;
      height: 5rem;

      @include iota-breakpoint(xs) {
        height: 100%;
      }

      &::-webkit-input-placeholder {
        color: color(grey-400);
      }
    }
  }

  &__view {
    background-color: color(white);
    position: relative;
    width: 25%;
    display: inline-flex;
    height: 5rem;
    vertical-align: top;
    border-radius: 0 0.64rem 0.64rem 0;

    @include iota-breakpoint(xs) {
      width: auto;
      height: auto;
      border-radius: 0;
    }

    &-dropdown {
      position: absolute;
      white-space: nowrap;
      background-color: color(white);
      border-radius: 0 0 0.64rem 0.64rem;
      margin-left: 1rem;
      display: none;
      top: 100%;
      right: 0;

      @include iota-breakpoint(xs) {
        right: auto;
      }

      &.is-open {
        display: block;
      }
    }

    &-selected {
      display: flex;
      padding: 1rem;
      height: 100%;

      .c-link {
        &:before {
          background: color(grey-200);
          content: '';
          height: 100%;
          margin-right: 1.6rem;
          width: 0.1rem;
        }
      }
    }

    &-item {
      list-style: none;
      padding: 1.2rem 1.6rem;

      &--selected {
        display: none;
      }

      &:last-child {
        border-top: 1px solid color(grey-100);
      }
    }

    .c-link {
      color: color(grey-300);
    }
  }

  &__submit {
    margin-top: 2rem;
    white-space: nowrap;
    display: flex;

    @include iota-breakpoint(xs) {
      margin-top: 0;
    }
  }

  &__btn {
    &--large {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      font-size: 2rem;
      padding: 1.2rem 3.2rem;
      border-radius: 0.64rem ;
      width: 100%;
      justify-content: center;

      @include iota-breakpoint(xs) {
        border-radius: 0 0.64rem 0.64rem 0;
        width: auto;
      }
    }
  }

  &__notice {
    font-size: 1.4rem;
    padding: .5rem 0 0 .9rem;
    display: flex;
    align-items: center;

    &--main {
      display: none;
      @include iota-breakpoint(xs) {
        display: flex;
      }
    }

    > i {
      font-size: 1.8rem;
      margin-right: .5rem;
      display: none;
      @include iota-breakpoint(xs) {
        display: inline;
      }
    }
  }
}