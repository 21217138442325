.c-split-content {

  &__frame-container {
    display: flex;
  }

  &__frame {
    margin: 0 0 1rem 1rem;
    width: 100%;
    -webkit-box-shadow: -1rem 1rem 0px 0px rgba(221,221,221,1);
    -moz-box-shadow: -1rem 1rem 0px 0px rgba(221,221,221,1);
    box-shadow: -1rem 1rem 0px 0px rgba(221,221,221,1);

    &--left {
      margin: 0 1rem 1rem 0;
      -webkit-box-shadow: 1rem 1rem 0px 0px rgba(221,221,221,1);
      -moz-box-shadow: 1rem 1rem 0px 0px rgba(221,221,221,1);
      box-shadow: 1rem 1rem 0px 0px rgba(221,221,221,1);
    }
  }

  &__img {
    width: 100%;
  }
}