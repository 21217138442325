.item{
	word-break: break-all;
	transition: 250ms ease-out all;
	&--list{
		display:flex;
		overflow:hidden;
		cursor:pointer;
		background:#fff;
		position:relative;
		.item__add{
			display:none;
			@include iota-breakpoint(sm-max){
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			}
			i{
				pointer-events:none;
				font-size: 1.75rem;
				color: #fff;
				background: $orange;
				border-radius: 100%;
				box-shadow: 0 0 10px 5px #fff;
			}
		}
	}
	&--grid{
		width:25%;
		cursor:pointer;
		@include iota-breakpoint(sm-max){
			width:50%;
		}
		.item__inner{
			position:relative;
			margin:1em;
			background:#fff;
			pointer-events:none;
			overflow:hidden;
			@include iota-breakpoint(sm-h){
				margin:0.25em;
			}
			@include iota-breakpoint(sm-max){
				margin:0.25em;
			}
			@include iota-breakpoint(sm-max){
				pointer-events:all;
			}
			img{
				width:100%;
				max-width:100%;
				display:block;
			}
		}
		.item__add{
			display:none;
			@include iota-breakpoint(sm-max){
				position: absolute;
				bottom: 0;
				right: 0;
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			i{
				pointer-events:none;
				font-size: 1.75rem;
				color: #fff;
				background: $orange;
				border-radius: 100%;
				box-shadow:0 0 10px 0px rgba(0,0,0,0.5);
			}
		}
		&.gu-mirror{
			transform:scale(0.9);
		}
	}
	&--map{
		display:flex;
		overflow:hidden;
		font-size: 1.35rem;
		word-wrap: break-word;
		cursor:pointer;
		background:#fff;
		border-bottom:1px solid $grey--1;
		color:#000;
		&_img{
			position:relative;
			width:50px;
			overflow:hidden;
			background:$dark;
			img{
				position: absolute;
				top:-100%;
				left:-100%;
				right:-100%;
				bottom:-100%;
				margin: auto;
				min-width: 100%;
				min-height: 100%;
				overflow-x: hidden;
			}
		}
		&_icon{
			width:50px;
			display:flex;
			align-items:center;
			justify-content:center;
			background:$dark;
			i{
				color:rgba(255,255,255,0.25);
				font-size:1.75em;
			}
		}
		p{
			margin:1em!important;
			flex:1;
			line-height:1.58;
			@include iota-breakpoint(sm-h){
				margin:0.75em!important;
			}
		}
		&:last-child{
			border:0;
		}
	}
	&__img{
		display:flex;
		flex:0 0 125px;
		position:relative;
		overflow:hidden;
		background:$dark;
		@include iota-breakpoint(md-max){
			flex:0 0 80px;
		}
		@include iota-breakpoint(sm-max) {
			flex: 0 0 50px;
		}
		img{
			position: absolute;
			top:-100%;
			left:-100%;
			right:-100%;
			bottom:-100%;
			margin: auto;
			min-width: 100%;
			min-height: 100%;
			overflow-x: hidden;
		}
		&_icon{
			font-family:"Icons";
			font-style:normal;
			font-weight:normal;
			color:rgba(255,255,255,0.25);
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size:3rem;
			@include iota-breakpoint(sm-max){
				font-size:2rem;
			}
		}
	}
	&__content{
		padding:2em 1em;
		flex:1;
		pointer-events:none;
		display:flex;
		flex-flow:column nowrap;
		justify-content:center;
		overflow-x:hidden;
		@include iota-breakpoint(sm-h){
			padding:1em;
		}
		@include iota-breakpoint(sm-max){
			padding:1em;
		}
		&_title{
			font-weight:700;
			margin:0;
		}
		&_subtitle{
			font-style:italic;
			font-size:0.75rem;
			color:#555;
			margin:0.25em 0 0.5em;
			white-space:nowrap;
			display:flex;
			@include iota-breakpoint(md-max){
				font-size:0.5rem;
				display:block;
			}
			@include iota-breakpoint(sm-max){
				white-space:normal;
			}
			span{
				// margin:0 0.5em;
				display:flex;
				align-items:center;
				@include iota-breakpoint(md-max){
					display:inline-flex;
				}
				&:after{
					content:"|";
					margin:0 0.5em;
				}
				&:first-child{
					margin-left:0;
				}
				&:last-child{
					max-width: 370px;
					&:after{
						display:none;
					}
				}
			}
			i{
				font-size:1em;
				line-height:1em;
			}
		}
		&_preview{
			margin:0;
		}
	}
	&__delete{
		background:#c53e3e;
		color:#fff;
		display:flex;
		align-items:center;
		justify-content:center;
		width:0px;
		transition:$eo100;
		@include iota-breakpoint(md-max){
			flex:0 0 3.75em;
		}
		@include iota-breakpoint(md-max){
			flex:0 0 2.5em;
		}
		@include iota-breakpoint(sm-max){
			display:none;
		}
		i{
			pointer-events:none;
		}
		&:hover{
			background:lighten(#c53e3e,10%);
		}
	}
	&__addnote{
		background:$orange;
		color:#fff;
		display:flex;
		align-items:center;
		justify-content:center;
		width:0px;
		transition:$eo100;
		@include iota-breakpoint(md-max){
			flex:0 0 3.75em;
		}
		@include iota-breakpoint(sm-max){
			flex:0 0 2.5em;
		}
		@include iota-breakpoint(sm-max){
			display:none;
		}
		i{
			pointer-events:none;
		}
		&:hover{
			background:lighten($orange,10%);
		}
	}
	&[data-present=true] {
		.item__toggle_icon--disabled {
			display: none;
		}
	}
	&[data-present=false] {
		.item__toggle_icon--enabled {
			display: none;
		}
	}
	&__toggle{
		background:$light;
		color:#fff;
		display:flex;
		align-items:center;
		justify-content:center;
		width:0px;
		transition:$eo100;
		@include iota-breakpoint(md-max){
			flex:0 0 3.75em;
		}
		@include iota-breakpoint(sm-max){
			flex:0 0 2.5em;
		}
		@include iota-breakpoint(sm-max){
			display:none;
		}
		i{
			pointer-events:none;
		}
		&:hover{
			background:lighten($light,10%);
		}
	}
	&__drag{
		flex:0 0 2.75em;
		background:#d4d8d9;
		cursor: move;
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
		display:flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		@include iota-breakpoint(md-max){
			flex:0 0 3.75em;
		}
		@include iota-breakpoint(sm-max){
			flex:0 0 2.5em;
		}
		@include iota-breakpoint(sm-max){
			display:none;
		}
		i{
			pointer-events:none;
			color:darken(#d4d8d9,15%);
			// transform:rotate(45deg);
		}
	}
	&__legend {
		font-size: 1.2rem;
		padding: .2rem .5rem;
		max-width: 20rem;

		&:not(:first-child) {
			margin-top: .8rem;
		}

		hr {
			margin: .3rem 0;
		}

		&-title {
			font-weight: 500;
		}
		&-list {
			display: flex;
			flex-direction: column;
		}
		&-fill {
			display: flex;
			align-items: center;
			margin: .2rem;
			>div:first-child {
				width: 1.4rem;
				height: 1.4rem;
				margin-right: .5rem;
			}
		}
	}
	&.gu-mirror{
		opacity:1!important;
		border:0;
		height:auto!important;
		box-shadow:0 0.5em 3em rgba(0,0,0,0.25);
		transform-origin:right;
		.item__inner{
			margin:0;
		}
	}
	&:hover{
		.item__delete,.item__addnote, .item__toggle{
			width:50px;
			@include iota-breakpoint(md-max){
				width:0px;
			}
		}
	}
}

@keyframes item {
	0%{
		// transform: rotate(-0.15deg);
		opacity:0.15;
	}
	100%{
		// transform:rotate(0.15deg);
		opacity:0.5;
	}
}