.c-menu {
  list-style: none;
  display: block;
  color: color(grey-200);
  width: 100%;
  
  @include iota-breakpoint(md) {
    align-items: center;
    justify-content: space-evenly;
    display: flex;
  }

  &__item {
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1.4rem;

    @include iota-breakpoint(md) {
      padding: 0;
    }

    &:last-child {
      margin-bottom: 3rem;

      @include iota-breakpoint(md) {
        margin-bottom: 0;
      }
    }

    &--user {
      position: relative;
      padding: 0;

      // @include iota-breakpoint(md) {
      //   padding: 1.4rem;
      // }

      > button {
        display: none;

        @include iota-breakpoint(md) {
          display: flex;
        }
      }
    }
  }

  &__link {
    cursor: pointer;
    transition: 0.3s ease all;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;

    @include iota-breakpoint(md) {
      font-size: inherit;
    }

    &:hover {
      color: color(white);
      background: transparent;

      @include iota-breakpoint(md) {
        color: color(white);
        background: color(teal);
      }
    }
  }

  &__avatar {
    background: url('../../../assets/images/default.jpg') center center no-repeat;
    background-size: cover;
    border-radius: 100%;
    margin:0 1rem;
    position: relative;
    height: 2.8rem;
    width: 2.8rem;
  }

  &__dropdown {
    background: transparent;
    border-radius: 0.64rem;
    box-shadow: 0 0 4.8rem rgba(0,0,0,.25);
    opacity: 1;
    display: block;
    position: relative;
    right: 0;
    top: 0;
    transition: opacity 0.3s;
    width: 100%;
    z-index: 10;

    @include iota-breakpoint(md) {
      opacity: 0;
      display: none;
      background: color(white);
      position: absolute;
      right: 1rem;
      top: calc(100% + 0.5rem);
    }

    &.is-open {
      display: block;
    }

    &:before {
      background: color(white);
      content: '';
      display: none;
      height: 1rem;
      margin: -0.5rem auto;
      position: relative;
      transform: rotate(45deg);
      width: 1rem;
      z-index: -1;

      @include iota-breakpoint(md) {
        display: block;
      }
    }

    &.active {
      opacity: 1;
    }

    &:hover + .c-menu__link {
      color: color(white);
      background: color(teal);
    }

  }

  &__dropdown-item {
    display: flex;
    list-style-type: none;
    color: color(grey-300);
    transition: 250ms ease-out all;
    z-index: 10;

    &:first-child {
      border-top-right-radius: 0.64rem;
      border-top-left-radius: 0.64rem;
    }

    &:last-child {
      border-bottom-right-radius: 0.64rem;
      border-bottom-left-radius: 0.64rem;
    }

    &:not(:last-child) {
      border-bottom: none;

      @include iota-breakpoint(md) {
        border-bottom: 0.1rem solid color(grey-100);
      }
    }

    &.is-last {
      button {
        background-color: color(orange);
        color: white;
        border-radius: 0.64rem;

        @include iota-breakpoint(md) {
          background-color: color(white);
          color: color(grey-300);

          &:hover {
            background-color: color(grey-100);
            color: color(grey-400);
          }
        }
      }
    }

    &:hover {
      color: color(white);
      z-index: 10;
      background: transparent;

      @include iota-breakpoint(md) {
        color: color(grey-400);
        background: color(grey-100);
      }
    }
  }

  &__dropdown-sub-item {
    height: 100%;
    text-align: center;
    width: 100%;
    z-index: 10;
    font-size: 1.8rem;
    padding: 1.4rem;
    padding-left: 3rem;
    font-weight: 700;

    @include iota-breakpoint(md) {
      text-align: left;
      padding-left: 1rem;
      font-size: 1.6rem;
      font-weight: normal;
    }
  }
}
