.c-results {
  background: color(grey-100);
  position: relative;
  overflow: auto;
  height: calc(100vh - 180px);
  padding: 1rem;

  @include iota-breakpoint(xs) {
    padding: 1.2rem;
  }
  @include iota-breakpoint(md) {
    height: calc(100vh - 27rem);
  }

  &--full {
    height: initial;
  }

  &--no-scroll {
    overflow: hidden;

    @include iota-breakpoint(sm) {
      overflow: auto;
    }
  }

  &--collection {
    height: calc(100vh - 190px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;

    @include iota-breakpoint(md) {
      flex-direction: row;
      overflow: auto;
    }
  }

  &__collection-container {
    // padding: 1%;
  }

  &__grid-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__grid-content {
    background: color(grey-100);
    overflow: auto;
    padding: 0.5%;
  }

  &__photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    grid-gap: 0.1rem 1.5rem;
    grid-auto-rows: 1rem;

    &_item {
      border: none;
      padding: 0;
      background-color: transparent;
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
