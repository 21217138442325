.c-checkbox {
  display: inline-flex;
  margin-right: 1rem;

  .c-btn {
    font-size: 1.6rem;
  }

  input:checked,
  input:hover {
    +.c-btn {
      background-color: color(teal);
      color: color(white);
    }
  }

  input:focus {
    +.c-btn {
      border-color: color(teal);
      outline: auto 0.5rem color(teal);
      outline-offset: -0.2rem;
    }
  }
}