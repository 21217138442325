$font-size: 1.4rem;
$padding-block: 0.8rem;
$padding: $padding-block 1.6rem;
$view-contributor-spacing: .8rem;
$input-height: 6rem;
$icon-error: #D70000;

.c-contributor-icon {
  border: none;
  background: transparent;
  cursor: pointer;
  padding-top: $padding-block / 2;

  &:hover {
    g.collab-fill {
      fill: #91989C;
    }
  }

  &:disabled {
    cursor: default;

    g.collab-fill {
      fill: #91989C;
    }
  }

  g.collab-fill {
    fill: color(navy);
    transition: fill .3s ease;
  }

  &__container {
    position: relative;
  }
}

.c-contributor-section {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.5rem;
}

.c-contributor-text {
  font-size: $font-size;
}

.c-contributor-manage-button {
  font-size: $font-size;
  border: none;
  background: transparent;
  margin: $padding;
  cursor: pointer;

  &:hover, &:active {
    text-decoration: underline;
  }

  &[data-hascontributor='false'] {
    margin-left: 0;
  }
}

.c-modal-view-contributor {
  @include iota-breakpoint(md) {
    width: 50rem;
  }
}

.c-view-contributor__ul {
  list-style-type: none;
}

.c-view-contributor__li {
  margin-bottom: $view-contributor-spacing;
}

.c-view-contributor__list-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &--icon {
    height: 2rem;
    width: 2rem;
  }

  &--owner-tag {
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: .8rem;
    color: #416579;
  }

  &--email {
    font-size: 1.6rem;
    margin-inline: 1rem;
    font-weight: bold;
  }
}

.c-view-contributor__title {
  margin-bottom: $view-contributor-spacing;
}

.c-add-contributor__email {
  
  &--description {
    font-size: 1.4rem;
    color: #333333;
    line-height: 1.9rem;
    letter-spacing: -0.2px;
    margin-bottom: 1.6rem;
  }

  &--label {
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: -0.1px;
    line-height: 1.7rem;
    margin-bottom: .4rem;
  }

  &--input {
    position: relative;
    display: block;
    height: $input-height;
    min-width: 100%;
    box-shadow: none;
    padding: 2.2rem 1.7rem;

    &[data-error='true'] {
      border-color: color(red);
    }
  }

  &--input-container {
    position: relative;
    min-width: 100%;
    height: $input-height + 2.3rem;
  }

  &--feedback-container {

  }

  &--feedback-icon {
    color: $icon-error;
    position: absolute;
    right: 2rem;
    top: 1.75rem;
  }

  &--feedback-text {
    color: color(red);
  }

  &--submit-button {
    height: $input-height;
    margin-top: 1rem;
  }
}

.c-add-contributor__current {
  margin-block: 2.5rem;
  
  &--title {
    font-weight: bold;
  }

  &--description {
    font-size: 1.4rem;
    font-weight: bold;
  }

  &--ul {
    margin-top: $view-contributor-spacing;
    width: 100%;
  }

  &--li {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 3rem;
    min-width: 100%;
    margin-bottom: $view-contributor-spacing;
  }
}

.c-delete-contributor {
  position: relative;

  &__container {
    &.c-modal__overlay--after-open {
      background: transparent;
    }
  }

  &__icon {
    cursor: pointer;
    border: none;
    background: transparent;
    height: 100%;
  }

  &__confirmation {
    position: absolute;
    width: 17rem;
    

    &--button {
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 100%;
        padding: .8rem;
        border: 1px color(grey-100) solid;
        border-bottom-left-radius: .64rem;
        border-bottom-right-radius: .64rem;
      }

      &-yes {
      }

      &-no {
      }
    }
  }
}

.c-modal--delete {
  background-color: transparent;
}