.c-privacy-toggle {
  position: relative;
  height: 3.3rem;
  width: 18rem;
  
  &__container {
    left: 0;
    top: 0;
    align-items: center;
    border-radius: 1.5rem;
    background-color: #D8D8D8;
    display: inline-flex;
    height: 100%;
    transition: all 0.3s ease-in;
    width: 100%;
    position: absolute;
  }

  &__input {
    appearance: none;

    &:checked + .c-privacy-toggle__container {
      .c-privacy-toggle__tab {
        transform: translateX(2%);
      }
    }
  }

  &__tab {
    transform: translateX(175%);
    transition: all 0.3s ease-in;
    background-color: #FFFFFF;
    border-radius: 5rem;
    border: 1px solid #B0B0B0;
    box-sizing: border-box;
    display: inline-block;
    height: 2.7rem;
    position: absolute;
    width: 6.5rem;
  }

  &__text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }
}
