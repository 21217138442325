.c-language-tabber {
  background: color(white);
  display: flex;
  flex-direction: column;

  &__tab-list {
    display: flex;
    list-style-type: none;
  }

  &__tab {
    flex: 1;

    &--content {
      align-items: center;
      display: flex;
      font-weight: 700;
      justify-content: center;
      padding: 1.6rem;
      text-transform: capitalize;
      width: 100%;
    }
  }

  &__content {
    flex: 1;
    overflow: auto;
  }

  &--fill {
    overflow: hidden;
    flex: 1;
  }
}