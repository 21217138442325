.c-video {
  margin: 2.7rem 0;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
  }

  &--contribution {
    margin: 0;
    padding-bottom: 96%;
  }
}
