@import 'components/featuredCard';

.c-featured-collections {
  background: url("../../../../shared/assets/images/bg__featureditems.jpg");
  padding: 5.5rem 1.6rem;

  &__title {
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 5.1rem;
    text-align: center;
  }
}
