/*
* Iota initialize base
* Initialize is a modern, elegant and minimal combination of
* Normalize.css and CSS Reset. Compatible with IE10+
* https://www.iotacss.com/docs/base/initialize/
*/

$iota-base-initialize-box-sizing: 'border-box';
$iota-base-initialize-heading-size: true;
$iota-base-initialize-form-elements: true;
$iota-base-initialize-html5-form-elements: true;

@import 'node_modules/iotacss/base/initialize';

/*
* Additional ADK preferences
*/
html {
  // A font-size of 62.5% will create a base font-size of 10px in most cases
  font-size: 62.5%;
  height: 100%;
}

body {
  color: color(black);
  font: 400 1.6rem / 1.58 $Lato;
  height: 100%;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

button,
input,
textarea {
  font-family: inherit;
}

iframe,
fieldset {
  border: 0;
}

#root {
  height: 100%;
}

.nav-open {
  overflow: hidden;
}
