.c-bp-accordion {
  &__panel {
    padding: 0;

    .ant-collapse-content-box, .ant-collapse-content {
      padding: 0 !important;
    }

    &--l1 {
      background-color: color(white);
      border-right: 0.1rem solid color(grey-200);
      
      .ant-collapse-header {
        color: #000000;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 2.2rem 2rem !important;
        .c-link {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
        }
      }

      &.selected {
        > .ant-collapse-header {
          border-right: 0.8rem solid color(orange);
        }
      }
    }

    &--l2 {
      background-color: color(teal);
      border-bottom: 0.1rem solid color(black) !important;

      &.selected {
        background-color: #668B9F;
      }
      
      .ant-collapse-header {
        color: #FFFFFF !important;
        font-size: 1.4rem;
        font-weight: 700;
        padding: 1rem 1.6rem !important;
        text-transform: uppercase;
      }
    }
  }

  &__item {
    position: relative;
    background-color: color(navy);

    &:not(:last-child) {
      border-bottom: solid 0.1rem color(teal);
    }
  }

  &__link {
    display: block;
    padding: 0 3.2rem;
  }

  &__link-text {
    display: inline-block;
    color: white;
    font-weight: 700;
    padding: 1rem 0;

    &.active {
      border-bottom: solid 0.2rem color(orange);
    }
  }
}
