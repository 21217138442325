/*
* Timeline slider for map
*/

#map-timeline-slider{
	background-color:#fff;
	padding:7px 15px 0px 15px;
	display:inline-block;
	clear:none;
	float:left;
	border:2px solid rgba(0,0,0,0.2);
  background-clip:padding-box;
  box-sizing: content-box;
	height:37px;
	min-width:360px;
	border-radius: 5px;
    margin-top: 10px;
    margin-right: 10px;
	@include iota-breakpoint(sm-max){
		display:none!important;
	}
}

.leaflet-control-layers{
	display:inline-block;
	clear:none;
	float:none;
	@include iota-breakpoint(sm-max){
		display:none;
	}
}

#map-timeline-slider .map-datetimepicker{
	width:170px;
  border:0px;
  font: inherit;
  margin: 0;
}

#map-start-date,#map-end-date{
	color:#333;
}

#map-start-date{
	float:left;
	cursor:pointer;
}

#map-end-date{
	float:right;
	text-align:right;
	cursor:pointer;
}


#map-timeline-slider #slider-range{
	width:100%;
}

#map-timeline-slider #slider-time-container{
	width:100%;
	margin-top:3px;
}

#ui-datepicker-div{
	z-index: 1000 !important; /* show above timeline slider on map */
}

#map-timeline-slider .ui_tpicker_second_label{
	display:none;
}

#slider-ui{
	max-width:500px;
	width:100%;
}

.ui_tpicker_unit_hide {
	display:none;
}

/*
* Leaflet Layer Control
*/
.leaflet-control-layers-base, .control-layers-overlays {
	max-height:400px;
	overflow:auto;
}

.leaflet-container {
	cursor:default;
}

/*
* Leaflet Map Legends
*/
#legend-container {
	min-width:125px;
	background-color:#fff;
	opacity:.95;
	padding:5px;
	overflow-x:hidden;
  box-shadow: 0 1px 5px rgba(0,0,0,.4);
  border-radius: 4px;
  margin-left: 10px;
  margin-bottom: 5px;
  max-height: 55vh;
  overflow-y: scroll;
  pointer-events: initial;
}

.legend {
	padding-bottom:10px;
}

.legend .legend-title{
	font-weight: bold;
}

#map-layers-control{
  margin-right: 10px;
  margin-top: 10px;
  float: right;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0,0,0,.4);

  .control-layers-list{
    display: none;

    label {
      display: block;
      font-weight: bold;
    }
  }
}
.control-layers-toggle{
    background-image: url(../assets/images/layers-2x.png);
    background-size: 26px 26px;
    width: 36px;
    height: 36px;
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.control-layers-expanded {
    padding: 6px 10px 6px 6px;
    color: #333;
    background: #fff;

    .control-layers-toggle{
        display: none;
    }

    .control-layers-list{
        position: relative;
        display: block !important;
    }
}

.heatmap-sl-overlay hr {
    border: 1px solid #eee;
}

.heatmap-sl-overlay input {
    background-color: transparent;
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 0;
    cursor: ew-resize;
}
