.c-toast {
  &:before {
    font-weight: 700;
    display: none;
    overflow: hidden;
    margin-bottom: 6px;
    padding: .25em .5em;
    content: 'JDA';
    transition: 250ms ease-out all;
    text-align: center;
    color: #fff;
    border-radius: .4em;
    border-top-left-radius: .4em;
    background: color(navy);
    box-shadow: 0 0 3em rgba(255,255,255,.5);
  }
  
  &__container {
    border-radius: 0.64rem;
    background-repeat: no-repeat;
    background-position: 1.5rem center;
    padding-left: 5rem;

    &--success {
      background-color: #51a351;
      background-image: url('../../../assets/icons/check.png');
    }

    &--error {
      background-color: color(red);
      background-image: url('../../../assets/icons/error.png');
    }

    &--info {
      background-color: #2f96b4;;
      background-image: url('../../../assets/icons/info.png');
    }
  }
}
