.c-contribution-item {
  height: 100%;
  width: 100%;

  &__tabber {
    height: 100%;
    width: 100%;
    
    &--preview {
      border-radius: 0.64rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__scroll {
    overflow: auto;
  }

  &__media {
    float: left;
    max-width: 50%;
    margin: 0 3.2rem 1.6rem 0;

    &--fill-width {
      width: 50%;
    }

    &--detail {
      float: unset;
    }
  }

  &__intro {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &__location {
    display: flex;
    margin: 0 -3.2rem;
    padding: 1rem;
    background: color(grey-100);
    align-items: flex-end;
    justify-content: space-between;

    span {
      font-size: 1.2rem;
      font-weight: 700;
      display: flex;
      align-items: center;

      .material-icons {
        font-size: 1.6rem;
        margin-right: 0.3rem;
      }
    }

    > span {
      font-size: 1.6rem;
    }
  
    &-coords {
      display: flex;
  
      span {
        margin-left: 1.36rem;
        font-size: 1.36rem;
        font-weight: 400;
      }
    }

  }

  &__add-btn {
    padding: 0.8rem;
    font-weight: 400;
    line-height: 1;
  }

  &__view-source {
    line-height: 1;
    padding: 0.7rem 0.6rem;
  }

  &__related-collections-img {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 24rem;
  }

  &__related-collection {
    cursor: pointer;
  }

  &__text-slide {
    white-space: pre-wrap;
  }
}