/*
* Iota display utility classes
* https://www.iotacss.com/docs/utilities/display/
*/

$iota-utils-display-namespace             : '' !default;
$iota-utils-display-flex-name             : 'flex' !default;
$iota-utils-display-block-name            : 'block' !default;
$iota-utils-display-hidden-name           : 'hidden' !default;
$iota-utils-display-inline-name           : 'inline' !default;
$iota-utils-display-inline-block-name     : 'inline-block' !default;
$iota-utils-display-inline-flex-name      : 'inline-flex' !default;

$iota-utils-display-res                   : true !default;
$iota-utils-display-breakpoints           : $iota-global-breakpoints !default;

@import 'node_modules/iotacss/utilities/display';

.#{$iota-utils-display-var-display + $iota-utils-display-inline-flex-name} {
  display: inline-flex !important;
}
// Display Responsive Utilities

@if ($iota-utils-display-res == true) {
  @each $breakpoint-name, $breakpoint-size in $iota-utils-display-breakpoints {
    @media #{$breakpoint-size} {

      .#{$iota-utils-display-var-display + $iota-utils-display-inline-flex-name + $iota-global-breakpoint-separator + $breakpoint-name} {
        display: inline-flex !important;
      }
    }
  }
}
