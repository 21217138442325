.react-select-box-container {
  position: relative;
  width: 100%;
  display: inline-block;
  background-color: #fff;
  border-radius: 0.64rem;
  text-align: left;
  border: 0.1rem solid color(grey-200);
  z-index: 99;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
.react-select-box-open{
  :focus {
    border-color: color(teal);
    outline: auto 0.5rem color(teal);
    outline-offset: -0.2rem;
  }
  .react-select-box:after{
    transform: translate3d(0, -50%, 0) rotate(-45deg);
    transition: .3s ease all;
    top: 1.8rem;
  }
}

.react-select-box {
  display: inline-block;
  cursor: pointer;
  border: none;
  width: 100%;
  text-align: left;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.react-select-box:focus {
  border-color: color(teal);
  outline: auto 0.5rem color(teal);
  outline-offset: -0.2rem;
}

.react-select-box:before {
  // content: '';
  // z-index: 1;
  // position: absolute;
  // height: 2rem;
  // top: 0.8rem;
  // right: 3.4rem;
  // border-left: 0.1rem solid #6EA2F8;
}

// .react-select-box:after {
//   content: '';
//   position: absolute;
//   z-index: 1;
//   top: 1.5rem;
//   right: 1.2rem;
//   width: 0.8rem;
//   height: 0.8rem;
//   background: transparent;
//   border-top: 0.2rem solid white;
//   border-right: 0.2rem solid white;
//   transform: translate3d(0,-50%,0) rotate(-225deg);
//   transition: .3s ease all;
// }

.react-select-box-label,
.react-select-box-option {
  line-height: 1.5rem;
  font-size: 1.3rem;
  color: color(navy);
}

.react-select-box-label {
  padding: 0.7rem 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.6rem;
  line-height: normal;
}

.react-select-box-empty .react-select-box-label {
  color: color(navy);
}

.react-select-box-click-outside-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.react-select-box-clear {
  display: none;

}

.react-select-box-clear:hover,
.react-select-box-clear:focus {
  border-color: color(teal);
  outline: auto 0.5rem color(teal);
  outline-offset: -0.2rem;
}

.react-select-box-clear:hover:before,
.react-select-box-clear:focus:before {
  background-color: #0493D1;
}

.react-select-box-hidden {
  display: none
}

.react-select-box-options {
  margin: .7rem 0 0;
  position: absolute;
  padding: 1rem 0;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: #fff;
  border-radius: 0.3rem;
  border: 0.1rem solid color(grey-200);
}

.react-select-box-options-list {
  list-style: none outside;
  margin: 0;
  padding: 0;
}

.react-select-box-option {
  padding: 0.5rem 1rem;
  margin: 0;
  cursor: pointer;
  display: block;
  line-height: 1.3rem;
  font-size: 1.3rem;
  text-decoration: none;
}

.react-select-box-option:hover {
  color: color(teal);
  background-color: #f4f4f4;
}

.react-select-box-option-selected {
  color: color(teal);
}

.react-select-box-multi .react-select-box-option {
  // padding-left: 4rem;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;

  .material-icons {
    margin-right: 0.5rem;
  }

  .checked {
    display: none;
  }
  .unchecked {
    display: inline;
  }
}

.react-select-box-multi .react-select-box-option:before {
  // content: url('../uncheck.png');
  position: absolute;
  line-height: 1;
  text-align: center;
  left: 1.5rem;
  top: 1rem;
}

.react-select-box-multi .react-select-box-option-selected:before {
  // content: url('../check.png');
}


.react-select-box-multi .react-select-box-option-selected {
  color: color(teal);
  // font-weight: 700;

  .checked {
    display: inline;
  }
  .unchecked {
    display: none;
  }
}

.react-select-box-option:focus,
.react-select-box-option-focused {
  border-color: color(teal);
  outline: auto 0.5rem color(teal);
  outline-offset: -0.2rem;
}

.react-select-box-close {
  color: color(teal);
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  padding: 0.5rem 0;
  display: block;
  text-align: center;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.react-select-box-close:hover,
.react-select-box-close:focus {
  text-decoration: underline;
}

.react-select-box-empty .react-select-box-close {
  color: color(teal);
}

.react-select-box-native {
  position: absolute;
  left: -99999em;
}
