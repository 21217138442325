.c-inline-col-form {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 100rem;

  &__main {
    max-width: 64rem;
  }

  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &.is-closed {
    max-height: 0;
  }

  .ck-editor__editable {
    height: 150px !important;
  }
}