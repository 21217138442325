@import 'components/CollectionPreview/collection-preview';
@import 'components/PrivacyToggle/privacy-toggle';

.c-item-modal {

  &:focus {
    outline: none;
  }

  &__column {
    position: absolute;
    height: 100%;
    top: 0;
    background: color(white);
    transition: 300ms ease-out all;
    transition-delay: 250ms; // makes transition less jarring when modal is loaded
    
    &--left {
      left: 0;
      transform: translateX(-100%);
    }
  
    &--right {
      right: 0;
      transform: translateX(100%);
    }

    &--wide {
      width: 85%;

      @include iota-breakpoint(md) {
        width: 50%;
      }
    }

    &--narrow {
      display: none;
      @include iota-breakpoint(md) {
        display: block;
        width: 25%;
      }
    }
  }

  &.is-open {
    .c-item-modal__column {
      transform: translateX(0);
    }
  }

  &.is-closed {
    .c-item-modal__column {
      transition-delay: 0ms;
      &--left {
        transform: translateX(-100%);
      }
  
      &--right {
        transform: translateX(100%);
      }
    }
  }

  &__sub-col {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__scrollable-content {
    flex: 1;
    overflow-y: auto;
  }

  &__collection-item {
    border-bottom: 0.1rem solid color(grey-300);
    margin: 0;
  }
}
