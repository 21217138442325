.c-search-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  justify-content: center;
  min-height: 38.1rem;
  width: 100%;

  &__title {
    font-size: 3.2rem;
    font-weight: 700;
    text-align: center;
    
    @include iota-breakpoint (md) {
      font-size: 4.8rem;
    }
  }

  &__caption {
    font-size: 1.6rem;
    margin-bottom: 4.8rem;
    text-align: center;

    @include iota-breakpoint (md) {
      font-size: 2rem;
    }
  }

  &__learn {
    text-decoration: none;
    color: color(orange);
    border-bottom: 0.1rem solid color(orange);

    &--icon {
      border-bottom: none;
    }
  }
}