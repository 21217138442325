.c-footer {
  z-index: 11;
  background: color(navy-200);
  
  @include iota-breakpoint(md) {
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__links {
    display: flex;
    flex-direction: column;
    
    @include iota-breakpoint(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__col {
    display: flex;
    align-items: center;
    align-items: center;
    padding: 1rem;

    @include iota-breakpoint(md) {
      padding: inherit;
    }

    &--social {
      position: relative;
    }
  }

  &__harvard {
    background: url('../../../../shared/assets/images/ui__harvard.png') center center no-repeat;
    height: 4.8rem;
    width: 5rem;
    align-items: center;
  }

  &__copyright {
    color: color(white);
    background: color(navy-100);
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;
  }

  &__contact {
    font-size: 1.4rem;
    letter-spacing: -0.1px;
  }

  &__url {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
