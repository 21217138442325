.c-collection-gallery {
  &__container {
    display: flex;
    overflow: auto;
    width: 100%;
    margin: -0.8rem;
    padding: 0;
    list-style: none;
    flex: 1;
  }

  &__tile {
    position: relative;
    overflow: hidden;
    width: 12rem;
    height: 12rem;
    margin: 0.8rem;
    cursor: pointer;
    flex-shrink: 0;
    &.current-image {
      outline: 5px solid color(orange);
    }
    &.selected {
      outline: 5px solid color(orange);
      &:before {
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: ' ';
        background: rgba(0,0,0,.7);
      }
      &:after {
        font-size: 1.3em;
        font-weight: 700;
        position: absolute;
        z-index: 100;
        top: 50%;
        left: 50%;
        content: "✔";
        transform: translate(-50%, -50%);
        color: rgb(255, 255, 255);
      }
    }
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%,-50%);
  }
}
