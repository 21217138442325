.c-collection-item {
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: initial;
  margin: 1rem 0;

  &:first-child {
    margin-top: 0;
  }

  @include iota-breakpoint(xs) {
    margin: 1.2rem 0;
    min-height: 12.5rem;
  }

  &--vertical {
    height: 100%;
  }

  &--collectionsearch {
    margin: 0;
  }

  &--mini {
    min-height: 6.45rem;
  }

  @include iota-breakpoint(md) {
    &:hover {
      .c-collection-item__actions {
        transform: translateX(0);
      }
    }
  }

  &--handle-right {
    .c-collection-item__actions.is-shown {
      transform: translateX(-4rem);
    }

    @include iota-breakpoint(md) {
      &:hover {
        .c-collection-item__actions {
          transform: translateX(-4rem);
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: color(black);
    background: color(white);
    border-radius: 0;

    &--vertical {
      flex-direction: column;
    }

    &--interactable {
      cursor: pointer;
    }
  }

  &__drag {
    display: none;
    z-index: 3;
    cursor: move;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
    @include iota-breakpoint(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: color(grey-200);
      color: color(grey-300);
    }

    &--default {
      width: 4rem;
    }

    &--vertical {
      width: 100%;
      padding: 1.6rem 0;
    }
  }

  &__img {
    height: 100%;
    width: 5.5rem;
    background-position: center;
    background-size: cover;
    background-repeat: repeat;

    @include iota-breakpoint(sm) {
      width: 12.5rem;
    }

    &--vertical {
      width: 100%;
      height: 20rem;

      @include iota-breakpoint(md) {
        height: 24rem;
      }
    }

    &--mini {
      width: 10%;
    }

    &--placeholder {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: color(navy);
      color: rgba(255,255,255,.25);

      .material-icons {
        font-size: 3.2rem;
      }
    }
  }

  &__content {
    flex: 1;
    padding: 1rem 6.6rem 1rem 1.2rem;

    @include iota-breakpoint(xs) {
      padding-right: 7rem;
    }

    @include iota-breakpoint(md) {
      padding: 1.6rem;
    }

    &--default {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 5.5rem);

      @include iota-breakpoint(md) {
        // any reason for this large margin right? Dont think so
        // margin-right: 20rem;
        width: calc(100% - 12.5rem);
      }

      .c-heading {
        font-size: 1.2rem;
        line-height: 1.2;

        @include iota-breakpoint(xs) {
          font-size: 1.6rem;
          line-height: 1.5;
        }
      }
    }

    &--mini {
      background: color(grey-100);
    }

    &--actions-left {
      margin-right: 0;
      margin-left: 4.8rem;
    }
  }

  &__subtitle {
    color: color(navy);
    flex-wrap: wrap;
    margin: 0 -1rem;

    .material-icons {
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }
  }

  &__subitem {
    margin: 0 1rem;
    position: relative;
    line-height: 1.3;
    font-size: 1rem;
    word-break: break-word;

    @include iota-breakpoint(xs) {
      line-height: 1.5;
      font-size: 1.2rem;
    }

    &:not(:last-of-type) {
      &::after {
        content: '|';
        position: absolute;
        font-style: normal;
        right: -1.2rem;
        top: 0;
      }
    }
  }

  &__blurb {
    position: relative;
    margin-top: 1rem;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.2;
    white-space: pre-wrap;

    ul, ol {
      padding-left: 3rem;
    }

    @include iota-breakpoint(xs) {
      font-size: 1.6rem;
      line-height: 1.5;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      // background: linear-gradient(180deg, rgba(255,255,255,0) 0%,color(white) 100%);
    }

    &--fade {
      &::after {
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%,color(white) 100%);
      }
    }
  }

  &__actions {
    transition: 0.3s ease all;
    position: absolute;
    right: 0;
    top: 0;
    height: 33.3333%;
    transform: translateX(0);
    z-index: 2;
    background-color: color(white);

    @include iota-breakpoint(md) {
      display: flex;
      height: 100%;
      transform: translateX(100%);
    }

    &--left {
      left: 0;
      right: unset;
    }

    &--move {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    &.is-shown {
      transform: translateX(0);
      display: flex;
      background: white;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: flex-end;

      @include iota-breakpoint(xs) {
        width: auto;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -3rem;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%,color(white) 100%);
          width: 3rem;
          height: 100%;
        }
      }
    }

    &--mini {
      &.is-shown {
        width: auto;
        background: inherit;
        @include iota-breakpoint(xs) {
          &::before {
            background: color(grey-100);
          }
        }
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: color(white);
    height: 100%;
    border: none;
    -webkit-appearance: none;
    padding: 0 1.5rem;

    &:hover {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &--corner-tr {
      height: initial;
      padding: 1.6rem;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__select {
    display: flex;
    align-items: normal;
    flex-direction: column;
    justify-content: center;
    // margin-right: 1.8rem;
    padding: 0 1.8rem;
  }

  &__select-label {
    margin-right: 0.8rem;
  }

  &__mini-checkbox {
    height: 100%;
    
    div {
      background: color(teal);
      color: white;
      height: 100%;
      padding: 0 0.8rem;
      transition: 100ms linear all;
    }

    input:checked + div {
      background: #2f4a58;
    }
  }

  &__notes {
    padding: 1.02rem;
    border-radius: 0.544rem;

    .material-icons {
      font-size: 1.36rem;
      margin-right: 0.68rem;
    }
  }

  &__mobile-add {
    position: absolute;
    right: 0.1rem;
    top: 40%;
  }
}

.is-dragging {
  .c-collection-item__drag {
    cursor: grabbing;
  }
}