.c-date-picker {
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 999;
  }

  .react-datepicker {
    font-size: 16px;

    &__input-container {
      width: 100%;
    }

    &__header {
      padding-top: 1.2rem;
    }

    &__month {
      margin: 0.6rem 1.5rem;
    }

    &__day {
      width: 3rem;
      line-height: 2;
      margin: 0.25rem;

      &--outside-month {
        color: color(grey-200);
      }

      &-name {
        width: 3rem;
        line-height: 2;
        margin: 0.25rem;
      }
    }

    &__current {
      &-month {
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
      }
    }

    &__navigation {
      top: 1.6rem;
      line-height: 1.75;
      border: 0.9rem solid transparent;

      &--previous {
        border-right-color: color(grey-200);
        left: 4.5rem; 
      }

      &--next {
        border-left-color: color(grey-200); 
        right: 4.5rem; 
      }
    }

    &__time {
      &-container {
        width: 9.6rem
      }
    }

    &-time {
      &__header {
        font-size: 1.6rem;

        &--time {
          padding-left: 0;
          padding-right: 0
        }
      }
    }

    &__time {
      &-box {
        margin-left: 0;
        margin-right: 0;
        width:100% !important;
      }

      &-list {
        padding: 0;
      }
    }

    select {
      padding: 0;
    }
  }
}