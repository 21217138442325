.c-copy {
  line-height: 1.58;

  &--xs {
    font-size: 1.2rem;
  }

  &--sm { // consolidate w/ ~13.6px
    font-size: 1.4rem;
  }

  &--italic { // make utility if this needs to be applied where copy or BEM doesnt make sense
    font-style: italic;
  }

  &--bold { // make utility if this needs to be applied where copy or BEM doesnt make sense
    font-weight: $bold;
  }

  &--underline { // make utility if this needs to be applied where copy or BEM doesnt make sense
    text-decoration: underline;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--icon {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;

    &--left {
      margin-right: 1rem;
    }

    &--right {
      margin-left: 1rem;
    }

    &--sm {
      .material-icons {
        font-size: 1.4rem;
      }
    }

    &--md {
      .material-icons {
        font-size: 1.6rem;
      }
    }

    &--lg {
      .material-icons {
        font-size: 3.2rem;
      }
    }
  }
}
