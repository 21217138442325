.c-map {
	position: absolute;
	z-index: 96;
	height: 100%;
	width: 100%;
	top: 0;
  left: 0;

  &--contributeMap,
  &--contributeMapPreview {
    height: 25rem;
    position: relative;
    margin-top: 2rem;
  }

  &--itemMap,
  &--previewMap {
    height: 22.5rem;
    position: relative;
    margin: 0 -3.2rem;
    width: auto;
  }

  &--previewMap {
    pointer-events: none;
  }

  &--contributeMap,
  &--contributeMapPreview,
  &--itemMap,
  &--previewMap {
    .point-marker {
      display: block;
      width: 16px;
      height: 16px;
      padding: 0;
      cursor: pointer;
      border: none;
      border-radius: 50%;
      background-color: red;
    }
  }

  &__loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
  }

  &__loading-text {
    position: absolute;
    bottom: 34px;
    right: 55px;
    font-size: 1.4rem;
    color: color(grey-100);
  }

  &__label-hide {
    display: none;
  }

	.mapboxgl-popup {
		font-family:$lato!important;
		font-size: 16px!important;
    line-height: 1.58!important;
    max-width: initial !important;
	}
	.mapboxgl-popup-close-button {
    font: 16px/14px Tahoma,Verdana,sans-serif;
		top:0.25em;
    color: #c3c3c3;
    padding: 4px 4px 0 0;
    text-align: center;
    font-weight: 700;

    @include iota-breakpoint(sm-max){
			top:0;
			font-size: 2.25rem;
			height: auto;
			width: auto;
			line-height: 1;
		}
	}

    .count-popup {
      text-align: center;
		  color: rgb(255, 255, 255);
      top:-30px;
      min-width: 20px;
      border-radius: 4px;
      text-shadow: 2px 2px 4px rgb(3, 3, 3);
		  font-size: 24px;
    }

	.mapboxgl-popup{

        .mapboxgl-popup-content {
            padding: 0px;
            border-radius: 6px;
        }

		.mapboxgl-popup-content .proximity-popup{
            border-radius:$setting--border-radius;
            padding:0;
			width:400px!important;
			margin:0;
            box-shadow: 0 3px 14px rgba(0,0,0,.4);
            background: $dark;
            @include iota-breakpoint(sm-max){
				width:90vw!important;
			}
			h3{
				padding: 0.5em;
				margin:0;
				background: transparent;
				color:#fff;
			}
			.map__items{
				max-height:300px;
				overflow:auto;
				-webkit-overflow-scrolling: touch;
                border-radius: 0 0 $setting--border-radius $setting--border-radius;
				.item__drag{
          @include iota-breakpoint(md-max){
						display:none;
					}
				}
				.gu-transit{
					display:none;
				}
				&_loading{
					display:block;
					padding:0.5em;
                    background: white;
				}
			}
		}
	}

  .group-checkbox {
    label.collapsible {
      padding-left: 5px;
      span {
        font-weight: normal;
      }
    }
  }

  .layer-category-name-en {
    font-size: 1.5rem;
  }
}