
.c-loader {
  stroke: color(orange);
  text-align: center;

  svg {
    height: 10rem;
    transform: rotate(-90deg);
    width: 10rem;
  }

  circle {
    animation: load 2.3s infinite;
    stroke-dasharray: 156;
    stroke-dashoffset: 156;
  }

  &--md {
    svg {
      height: 6rem;
      width: 6rem;
    }
  }

  &--sm {
    svg {
      height: 3rem;
      width: 3rem;
    }
  }
}

@keyframes load {
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -156;
  }
}
