.c-sort-dropdown {
  position: relative;
  height: 100%;

  &--w-borders {
    border-right: 1px solid color(grey-300);
    border-left: 1px solid color(grey-300);
  }

  &__selector {
    align-items: center;
    display: flex;
    height: 100%;
    background: inherit;
    border: none;
  }

  &__label {
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: left;
  }

  &__selected {
    font-size: 1.36rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  &__list {
    position: absolute;
    left: 0;
    // top: 100%;
    width: 100%;
    z-index: 10;
    border-bottom-right-radius: 0.48rem;
    border-bottom-left-radius: 0.48rem;
    transition: max-height 0.25s ease-in-out;
    overflow: hidden;

    &.hidden {
      max-height: 0 !important;
      overflow: hidden;
    }

    &.loading {
      visibility: hidden;
    }
  }

  &__option {
    list-style-type: none;
    font-size: 1.36rem;
    font-weight: 700;
    padding: 1.6rem;
    transition: 100ms linear all;
    background: color(grey-200);
    border: none;
    border-top: 0.1rem solid color(grey-300);

    &:last-child {
      border-bottom-right-radius: 0.48rem;
      border-bottom-left-radius: 0.48rem;
    }

    &:hover, &:focus {
      background: color(grey-100);
    }

    &--selected {
      display: none;
    }
  }
}
