.c-captcha {
  border: 0.1rem solid color(grey-200);
  padding: .56rem 1rem 1.2rem;
  margin: 0 0.2rem;
  min-inline-size: inherit;

  &__description {
    margin-bottom: 1rem;
  }

  &__frame {
    display: flex;
    justify-content: center;
  }

  &__legend {
    width: auto;
  }

  &__fieldset-legend {
    font-size: 1.6rem;
  }
}