.c-heading {
  font-family: $Lato;
  font-weight: $bold;
  // pulled from live site remove if getting in the way
  margin-top: 0.3rem;
  margin-bottom: 0.8rem;

  &--l1 {
    font-size: 3.2rem;
  }

  &--l2 {
    font-size: 3rem;
  }

  &--l3 {
    font-size: 2.4rem;
  }

  &--l4 {
    font-size: 2rem;
  }

  &--l5 {
    font-size: 1.8rem
  }

  &--l6 {
    font-size: 1.6rem;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1;
  }
}
