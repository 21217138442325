.c-modal {
  background: color(white);
  height: auto;
  width: auto;

  &--centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--authentication {
    border-radius: 0.64rem;
    width: 90%;
  
    @include iota-breakpoint (md) {
      width: 40rem;
    }
  }

  &--confirmation {
    opacity: 0;
    transition: ease-in-out transform 0.3s, ease-in-out opacity 0.3s;
    transform: translate(-50%, -50%) scale(0.5,0.5);

    &.is-open {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1,1);
    }

    &.is-closed {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5,0.5);
    }
  }

  &__overlay {
    background: rgba(0,0,0,0);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    transition: background-color 150ms ease-in-out;
    z-index: 999;

    &--after-open {
      background: rgba(0,0,0,.5);
      overflow: scroll;
    }

    &--before-close {
      background: rgba(0,0,0,0);
    }
  }

  &__body {
    overflow: hidden;
  }

  &__notes-textarea {
    resize: none;
    width: 100%;
    border: none;
  }
}
