.c-cs-accordion {
  &__panel {
    padding: 0;

    .ant-collapse-content-box, .ant-collapse-content {
      padding: 0 !important;
    }

    .ant-collapse-arrow {
      color: color(white) !important;
      font-size: 3.2rem !important;

      &.selected {
        color: red;
      }
    }

    &--l1 {
      background-color: color(teal);
      border-bottom: 0.1rem solid color(grey-300) !important;
      
      .ant-collapse-header {
        div { 
          margin: 0;
        }
      }
    }

    &--over {
      div {
        opacity: 1 !important;
      }
    }
  }

  &__item {
    background: color(grey-100);
    cursor: pointer;
    margin: 0;
    padding: 0.8rem 1.6rem;

    &:not(:last-child) {
      border-bottom: 1px solid color(grey-200);
    }
  }
}
