@import './components/MyCollectionsAccordion/cs-accordion';

.c-collections__sidebar {
  &-header {
    display: flex;
    padding: 0 .5em;
    height: 6rem;
    color: color(grey-300);
    background: color(navy-200);
    align-items: center;
    justify-content: space-between;

    .c-link {
      color: color(orange);
    }
  } 

  &-ctabox {
    margin: 1.5rem;
    padding: 2rem 1rem;
    transition: 100ms linear all;
    opacity: .5;
    color: #fff;
    border: 4px dashed #fff;

    &.is-active {
      opacity: 1;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: calc(100vh - 27rem);
    overflow: auto;
  }
}
