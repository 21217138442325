.c-feature-link {
  position: relative;
  display: flex;
  background-color: #F8F8F8;
  box-shadow: 0 1px 3px 0 rgba(145,152,156,0.5);
  // height: 10.2rem; // is there a reason why we were setting a fixed height here?
  padding: 2rem 2.5rem;

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: -0.01rem;
    margin-bottom: 1rem;
  }

  &__description {
    font-weight: 500;
    letter-spacing: -0.015rem;
    line-height: 2.4rem;
  }

  &__left-col {
    flex: 1;
    padding-right: 1rem;;
  }

  &__right-col {
    display: flex;
    align-items: center;
  }

  &__arrow {
    color: color(black);
    font-size: 4rem;
  }

  &__url {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
