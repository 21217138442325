.c-confirmation-modal {
  opacity: 0;
  transition: ease-in-out opacity 0.2s;
  &.is-open {
    opacity: 1;
  }

  &.is-closed {
    opacity: 0;
  }
}
